// Menu Icon Color Config
var menuIconColorsObj = {
  iconStrokeColor    : "#364659",
  iconSolidColor     : "#364659",
  iconFillColor      : "#d4ebf9",
  iconStrokeColorAlt : "#ffc000"
};


// Active Menu Icon Color Config
var menuActiveIconColorsObj = {
  iconStrokeColor    : "#364659",
  iconSolidColor     : "#364659",
  iconFillColor      : "#d4ebf9",
  iconStrokeColorAlt : "#ffc000"
};
